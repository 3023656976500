var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReadyToDisplay)?_c('div',{class:[
    'ep-meeting-request-dashboard py-4 d-flex flex-column row-gap-6 border-1 border-neutral-n-4-line rounded-lg',
    _vm.classList,
    {
      'desktop': _vm.isDesktop,
      'tablet': _vm.isTablet,
      'mobile': _vm.isMobile,
    }
  ]},[_c('h3',{staticClass:"text-left m-0 px-4"},[_vm._v(" "+_vm._s(_vm.$t('ep-dashboard.meeting-request.title'))+" ")]),_c('div',{staticClass:"filters d-flex align-items-center column-gap-1 overflow-x-auto hide-scrollbar px-4"},_vm._l((Object.keys(_vm.filtersMenuOptions)),function(option,i){return _c('p',{key:i,class:['filter-item text-left cursor-pointer m-0 px-3 py-2 rounded-pill border-1 border-neutral-n-4-line',
               {'active': _vm.selectedFilter === option}
      ],on:{"click":function($event){return _vm.onFilter(option)}}},[_vm._v(" "+_vm._s(_vm.$t(("calendar.tabs.requests.filter." + option)))+" ")])}),0),_c('transition',{attrs:{"name":"fade"}},[(_vm.isLoading)?_c('div',{staticClass:"list-loading d-flex align-items-center justify-content-center flex-1 px-4"},[_c('loading-spinner-component')],1):(_vm.list.length > 0)?_c('div',{staticClass:"list d-flex flex-column flex-1 overflow-y-auto px-4"},_vm._l((_vm.list),function(mr){return _c('ep-meeting-request-dashboard-item-component',{key:mr.uid,attrs:{"meeting-request":mr,"selected-filter":_vm.selectedFilter}})}),1):_c('div',{staticClass:"list-empty d-flex align-items-center justify-content-center flex-1 px-4"},[_c('p',{staticClass:"m-0 paragraph-1 font-weight-bold text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.emptyMessage)+" ")])])]),_c('div',{staticClass:"px-4"},[_c('button-component',{attrs:{"text":_vm.$t('ep-dashboard.meeting-request.go-to-btn'),"size":"md","variant":"secondary"},on:{"on-click":_vm.goToMeetingRequests}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }